@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;700;900&family=Montserrat:wght@200;300;400;500;600;700&family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Poppins:wght@100;200;300;500;600;700;800&display=swap");

:root {
  --primaryGreen: #22c55e;
  --primaryBlue: #00c2ff;
  --primaryOrange: #ff8a00;
  --secondaryGreen: #5eead4;
  --secondaryBlue: #e2f8ff;
  --secondaryOrange: #fff6e4;
  --whiteColor: #ffffff;
  --blackColor: #2c2c2c;
  --gray: #9c9c9c;
  --body: #fafbfc;
  --poppins: "Poppins", sans-serif;
  --montserrat: "Montserrat", sans-serif;
  --sans: "PT Sans", sans-serif;
  --inter: "Inter", sans-serif;
  --shadow: 0px 6px 10px rgba(129, 129, 129, 0.1);
  --shadowStyle: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  --shadowHover: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}

* {
  font-family: var(--poppins);
}
body {
  background-color: var(--body) !important;
  height: 200vh;
}
.navbar {
  padding: 10px 0;
  background-color: white !important;
  box-shadow: var(--shadow);
}

.primaryBtn {
  padding: 12px 24px;
  gap: 10px;
  height: 45px;
  border: none;
  background: var(--primaryBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.secondaryBtn {
  padding: 12px 24px;
  gap: 10px;
  height: 45px;
  border: none;
  background: var(--secondaryBlue);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryOrange);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  min-width: 100px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.9s linear;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.primaryBtnStyle,
.sm-btnPrimary:hover {
  background: #00c2ff;
  background: linear-gradient(0deg, #00c2ff 0%, #00c2ff 100%);
  border: none;
}

.secondaryBtnStyle,
.sm-btnPrimary,
.searchInput {
  background: #e2f8ff;
  background: linear-gradient(0deg, #e2f8ff 0%, #e2f8ff 100%);
  border: none;
}

.primaryBtnStyle:before,
.secondaryBtnStyle:before,
.sm-btnPrimary:before,
.searchInput:before {
  height: 0%;
  width: 2px;
}

.primaryBtnStyle:hover,
.secondaryBtnStyle:hover,
.sm-btnPrimary:hover,
.searchInput:focus {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5), -4px -4px 6px 0 rgba(116, 125, 136, 0.5), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
}

.btnWrapper {
  gap: 12px;
}
.btnIcon {
  border: none;
  background-color: transparent;
}

.circleBadge {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--primaryBlue);
}
.nav-link.active {
  color: var(--primaryBlue) !important;
  font-weight: 600 !important;
  position: relative;
}
.nav-link.active::before {
  content: "";
  width: 60%;
  height: 2px;
  background: var(--primaryBlue);
  position: absolute;
  bottom: 6px;
  left: 8px;
}
.nav-link {
  position: relative;
}
.nav-link::before {
  content: "";
  width: 0;
  height: 2px;
  background: var(--primaryBlue);
  position: absolute;
  bottom: 6px;
  left: 8px;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover::before {
  content: "";
  width: 60%;
  height: 2px;
  background: var(--primaryBlue);
  position: absolute;
  bottom: 6px;
  left: 8px;
  transition: all 0.3s ease-in-out;
}

.carousel-control-next i,
.carousel-control-prev i {
  font-size: 30px;
}

#carouselExampleIndicators {
  margin-top: 60px;
}

.title-wrapper {
  background: var(--primaryBlue);
  background: linear-gradient(0deg, var(--primaryBlue) 0%, var(--primaryBlue) 100%);
  color: var(--whiteColor);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 24px 4px 10px;
  gap: 10px;
  width: 177px;
  box-shadow: var(--shadowStyle);
  cursor: help;
}

.card-category {
  background: var(--whiteColor);
  box-shadow: var(--shadow);
  transition: all ease-in 0.2s;
}

.category-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  color: var(--primaryOrange);
  background: var(--secondaryBlue);
  background: linear-gradient(0deg, var(--secondaryBlue) 0%, var(--secondaryBlue) 100%);
  box-shadow: var(--shadowStyle);
  transition: all ease-in 0.2s;
  cursor: pointer;
}

.card-category:hover {
  box-shadow: var(--shadowHover);
  transition: all ease-in 0.2s;
}

.card-category:hover .category-label {
  color: var(--whiteColor);
  background: var(--primaryBlue);
  background: linear-gradient(0deg, var(--primaryBlue) 0%, var(--primaryBlue) 100%);
  box-shadow: var(--shadowStyle);
  transition: all ease-in 0.2s;
}

/* Section Product  */

.tagline {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: var(--blackColor);
}

form {
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.searchInput {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 16px;
  gap: 10px;
  color: var(--primaryOrange);
  flex-grow: 1;
  border: none;
  outline: none;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  height: 45px;
  border-radius: 5px 0px 0px 5px;
  transition: all 0.9s linear;
}

.br {
  border-radius: 0px 5px 5px 0;
}

.product-card {
  background: var(--whiteColor);
  box-shadow: var(--shadowStyle);
  width: 100%;
  padding: 10px;
}
.image-wrapper {
  height: 200px;
  overflow: hidden;
}
.title-category {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray);
  display: block;
}

.title-brand {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--blackColor);
  display: block;
}
.title-description {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  display: block;
}

.sm-btnPrimary:hover {
  color: var(--whiteColor);
}
.sm-btnPrimary {
  padding: 4px 10px;
  background: var(--secondaryBlue);
  color: var(--primaryOrange);
  border: none;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5), 7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px  ;
  transition: all 0.2s linear;
}

@media screen and (max-width: 992px) {
  .nav-link:hover::before {
    width: 10%;
    position: absolute;
    bottom: 6px;
    left: 0px;
  }

  .nav-link.active::before {
    width: 8%;
    left: 0px;
  }
}
